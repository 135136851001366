var bbdc = (function ($) {
    'use strict';

    /**
     * Empty placholder function.
     *
     * @since 1.0.0
     */
    var parallax = function () {

            var scrolled = $(window).scrollTop();
            $('#smile .section__layer--background').css('top', (scrolled * 0.35) + 'px');
            $('#nhs-and-private-care .section__layer--background').css('top', ((scrolled * 0.2) - 52) + 'px');
            // $('#photo-gallery .section__layer--background').css('top', (scrolled * 1) + 'px');
        },

        galleryParallax = function() {

            var $galleryDiv = $('#photo-gallery .section__layer--background'),
                startPosition = $galleryDiv.offset().top,
                scrollPosition = $(window).scrollTop();

            if ( scrollPosition >= startPosition ) {
                $galleryDiv.css('top', '-' + (startPosition) + 'px');
            }

        },

        smileFadeToggle = function () {

            $('.toggle-panel-open, .toggle-panel-close').on('click', function() {
                $('.section__layer--top').fadeToggle();
            })

        },

        initTabs = function() {

            $('div.tabs').tabs();

        },


        carePlanTabs = function() {

            $('.care-plan-tabs').tabs();

        },

        treatmentsTabs = function() {

            $('div#treatments').tabs({
                // collapsible: true,
                // active: false
            });

        },

        testimonialSlider = function() {

            $('.home-testimonial-block').bxSlider({
                auto: true,
                controls: true,
                mode: 'fade',
                pager: false
            });

        },

        dentistryTabs = function() {

            $(".treatment-content--dentistry .js-vertical-tab-content").hide();
            $(".treatment-content--dentistry .js-vertical-tab-content:first").show();

            /* if in tab mode */
            $(".treatment-content--dentistry .js-vertical-tab").click(function(event) {
                event.preventDefault();

                $(".treatment-content--dentistry .js-vertical-tab-content").hide();
                var activeTab = $(this).attr("rel");
                $("#"+activeTab).show();

                $(".treatment-content--dentistry .js-vertical-tab").removeClass("is-active");
                $(this).addClass("is-active");

                $(".treatment-content--dentistry .js-vertical-tab-accordion-heading").removeClass("is-active");
                $(".treatment-content--dentistry .js-vertical-tab-accordion-heading[rel^='"+activeTab+"']").addClass("is-active");
            });

            /* if in accordion mode */
            $(".treatment-content--dentistry .js-vertical-tab-accordion-heading").click(function(event) {
                event.preventDefault();

                $(".treatment-content--dentistry .js-vertical-tab-content").hide();
                var accordion_activeTab = $(this).attr("rel");
                $("#"+accordion_activeTab).show();

                $(".treatment-content--dentistry .js-vertical-tab-accordion-heading").removeClass("is-active");
                $(this).addClass("is-active");

                $(".treatment-content--dentistry .js-vertical-tab").removeClass("is-active");
                $(".treatment-content--dentistry .js-vertical-tab[rel^='"+accordion_activeTab+"']").addClass("is-active");
            });


        },

        beautyTabs = function() {

            $(".treatment-content--beauty .js-vertical-tab-content").hide();
            $(".treatment-content--beauty .js-vertical-tab-content:first").show();

            /* if in tab mode */
            $(".treatment-content--beauty .js-vertical-tab").click(function(event) {
                event.preventDefault();

                $(".treatment-content--beauty .js-vertical-tab-content").hide();
                var activeTab = $(this).attr("rel");
                $("#"+activeTab).show();

                $(".treatment-content--beauty .js-vertical-tab").removeClass("is-active");
                $(this).addClass("is-active");

                $(".treatment-content--beauty .js-vertical-tab-accordion-heading").removeClass("is-active");
                $(".treatment-content--beauty .js-vertical-tab-accordion-heading[rel^='"+activeTab+"']").addClass("is-active");
            });

            /* if in accordion mode */
            $(".treatment-content--beauty .js-vertical-tab-accordion-heading").click(function(event) {
                event.preventDefault();

                $(".treatment-content--beauty .js-vertical-tab-content").hide();
                var accordion_activeTab = $(this).attr("rel");
                $("#"+accordion_activeTab).show();

                $(".treatment-content--beauty .js-vertical-tab-accordion-heading").removeClass("is-active");
                $(this).addClass("is-active");

                $(".treatment-content--beauty .js-vertical-tab").removeClass("is-active");
                $(".treatment-content--beauty .js-vertical-tab[rel^='"+accordion_activeTab+"']").addClass("is-active");
            });


        },

        registerScroll = function() {

            $('.menu-register a').on('click', function(e) {

                var offset = $('#contact').offset().top;
                $('html, body').animate({
                    scrollTop: offset
                }, 500, 'swing');

                e.preventDefault();

                $('.tabs').tabs("option", "active", 1);

            });

        },

        contactScroll = function() {

            $('.menu-contact a').on('click', function(e) {

                var offset = $('#contact').offset().top;
                $('html, body').animate({
                    scrollTop: offset
                }, 500, 'swing');

                e.preventDefault();

                $('.tabs').tabs("option", "active", 0);

            });

        },

        /**
         * Fire events on document ready, and bind other events.
         *
         * @since 1.0.0
         */
        ready = function () {
            smileFadeToggle();
            // galleryParallax();
            initTabs();
            treatmentsTabs({ active: 1});
            dentistryTabs();
            beautyTabs();
            registerScroll();
            contactScroll();
            carePlanTabs();
            testimonialSlider();
            $.mark.jump();
            $('.venobox').venobox();
            // $('.content').fullpage({
            //     sectionSelector: '.section--fullpage',
            //     scrollBar: true,
            //     fitToSection: false,
            //     normalScrollElements: '#meet-the-team, #treatments'
            // });

            // Examples binding to events.
            // $( window ).on( 'resize.bbdc', functionName );
            $(window).on('scroll.bbdc', parallax);
            // $(window).on('scroll.bbdc', galleryParallax);
        };

    // Only expose the ready function to the world
    return {
        ready: ready
    };

})(jQuery);

jQuery(bbdc.ready);
