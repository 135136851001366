var bittersMap = (function () {
    var myLatlng = new google.maps.LatLng(57.4693486, -1.7867074),
        mapCenter = new google.maps.LatLng(57.4783779,-1.7311197),
        mapCanvas = document.getElementById('contact-map'),
        mapOptions = {
            center: mapCenter,
            zoom: 12,
            scrollwheel: false,
            draggable: true,
            disableDefaultUI: false,
            mapTypeId: google.maps.MapTypeId.ROADMAP
        },
        map = new google.maps.Map(mapCanvas, mapOptions),
        contentString =
            '<div id="content">'+
            '<div id="siteNotice">'+
            '</div>'+
            '<div id="bodyContent"'+
            '<p><strong>Buchan Braes Dental Clinic</strong>,<br>Buchan Braes Business Park,<br>Boddam,<br>Aberdeenshire,<br>AB42 3AR</p>'+
            '</div>'+
            '</div>',
        infowindow = new google.maps.InfoWindow({
            content: contentString,
            maxWidth: 300
        }),
        marker = new google.maps.Marker({
            position: myLatlng,
            map: map,
            title: 'thoughtbot (Sweden)'
        });

    return {
        init: function () {
            map.set('styles', [{
                featureType: 'landscape',
                elementType: 'geometry'
            }
            ]);

            google.maps.event.addListener(marker, 'click', function () {
                infowindow.open(map,marker);
            });
        }
    };
}());

bittersMap.init();