CoolClock.config.skins = {

    classic/*was gIG*/: {
        outerBorder:      { lineWidth: 0, radius: 1, color: "#c89d6a", alpha: 1 },
        smallIndicator:   { lineWidth: 2, startAt: 89, endAt: 94, color: "#fff", alpha: 1 },
        largeIndicator:   { lineWidth: 4, startAt: 83, endAt: 94, color: "#fff", alpha: 1 },
        hourHand:         { lineWidth: 5, startAt: 0, endAt: 60, color: "white", alpha: 1 },
        minuteHand:       { lineWidth: 4, startAt: 0, endAt: 80, color: "white", alpha: 1 },
        secondHand:       { lineWidth: 1, startAt: -20, endAt: 85, color: "white", alpha: .85 },
        secondDecoration: { lineWidth: 3, startAt: 0, radius: 2, fillColor: "white", color: "white", alpha: 1 }
    }

};